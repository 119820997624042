/**
 * The QuickView uses the native HTML `<dialog>` element
 * to display a dialog with additional information about
 * the search result which can be read by the user before
 * they go to the result URL itself.
 * 
 * Browser support for the `<dialog>` element:
 * https://caniuse.com/dialog
 * 
 * A custom overlay is used for browsers that do not
 * support the `<dialog>` element. This element should
 * be a `<div>` with the class specified below as the
 * overlaySelector which appears as the first element
 * in the `<body>`.
 */

const dialogSelector = '.js-quick-view',
      linkQuickViewSelector = '.js-quick-link',
      buttonCloseSelector = '.quick-view__close',
      overlaySelector = '.overlay',
      invisibleClass = 'invisible', // prevents keyboard focus of child elements
      bodyClass = 'opened-view',    // activates overlay for browsers not supporting dialog
      noscrollClass = 'noscroll';   // adds `overflow: hidden`

function init() {
    addEventListeners();
}

/**
 * Shows the QuickView modal dialog that corresponds
 * to the targetSelector argument.
 * @param {*} targetSelector Selector for the QuickView to show
 */
function showQuickView(targetSelector) {

    if (document.querySelectorAll(dialogSelector).length > 0) {
        const dialog = document.querySelector(targetSelector)
        if (dialog) {
            dialog.classList.remove(invisibleClass)
            /**
             * Currently, Safari and Firefox do not support `<dialog>`
             * elements natively.
             */
            if (typeof dialog.showModal === 'function') {
                dialog.showModal()

                // Prevents scrolling of the rest of the page
                document.body.classList.add(noscrollClass)
            } else {
                dialog.setAttribute('open', '')
                // show custom overlay on browsers not supporting dialog
                // this also prevents scrolling
                document.body.classList.add(bodyClass)
            }
            
            // Move focus to the button that closes the quickview
            const closeButton = dialog.querySelector(buttonCloseSelector)
            if (closeButton) {
                closeButton.focus()
            } else {
                console.error('Expected a button with class "' + buttonCloseSelector + '" to exist within the quickview.')
            }

        } else {
            console.error('Expected a quickview section with selector "' + targetSelector + '" to exist.')
        }
    }
}

/**
 * Hides all currently open QuickView modal dialogs.
 */
function hideQuickView() {
    const openDialogs = document.querySelectorAll(dialogSelector + '[open]')
    openDialogs.forEach(function(dialog) {
        
        /**
         * Currently, Safari and Firefox do not support `<dialog>`
         * elements natively.
         */
        if (typeof dialog.close === 'function') {
            dialog.close()

            // Prevents scrolling of the rest of the page
            document.body.classList.remove(noscrollClass)
        } else {
            dialog.removeAttribute('open')
            // hide custom overlay on browsers not supporting dialog
            document.body.classList.remove(bodyClass)
        }
        /**
         * This is necessary to add the CSS `invisible` property 
         * with JS instead of using CSS transition for a smooth 
         * "slide out" because the `invisible` property is a 
         * multiple choice, not a value that can be transitioned.
         */
        window.setTimeout(function() {
            dialog.classList.add(invisibleClass) 
        }, 400)
    })
}

/**
 * Attaches event listeners to elements related to the 
 * QuickView feature.
 */
function addEventListeners() {

    /**
     * Attach event listeners to the buttons to open the
     * dialogs.
     * 
     * These first close the open dialog (if any), although
     * that should not be a situation that occurs because of
     * the dialog overlay.
     */
    const openButtons = document.querySelectorAll(linkQuickViewSelector)
    openButtons.forEach(function(button) {
        button.addEventListener('click', function(_event) {
            const target = this.dataset.target
            hideQuickView()
            showQuickView(target)
        })
    })

    /**
     * Each dialog will contain a button that closes that dialog.
     * 
     * This is the button that should generally receive first focus
     * when the dialog is opened.
     */
    const closeButtons = document.querySelectorAll(buttonCloseSelector)
    closeButtons.forEach(function(button) {
        button.addEventListener('click', function(_event) {
            hideQuickView()
        })
    })

    const dialogs = document.querySelectorAll(dialogSelector)
    dialogs.forEach(function(dialog) {
        /**
         * Browsers that currently support the native `<dialog>`
         * element provide a native overlay (and therefore 
         * `<dialog>` receives the click event).
         * 
         * This listener checks if the click was outside the visible bounds
         * of the <dialog> and should therefore close the dialog.
         * 
         * Further information:
         * https://stackoverflow.com/questions/25864259/how-to-close-the-new-html-dialog-tag-by-clicking-on-its-backdrop
         * 
         * Browsers may support a better way natively in the future.     
        */
        dialog.addEventListener('click', function(event) {
            const rect = event.target.getBoundingClientRect()
        
            const clickedInDialog = (
                rect.top <= event.clientY &&
                event.clientY <= rect.top + rect.height &&
                rect.left <= event.clientX &&
                event.clientX <= rect.left + rect.width
            )
        
            if (!clickedInDialog) {
                hideQuickView()
            }
        })
    })

    /**
     * For browsers that do not support the native `<dialog>` element,
     * we use a custom overlay element. When this element receives a click,
     * then the open dialog should be closed.
     * 
     * The overlay can be removed when all target browsers support 
     * the native `<dialog>` element.
     */
    const overlay = document.querySelector(overlaySelector)
    if (overlay) {
        overlay.addEventListener('click', function(_event) {
            hideQuickView()
        })
    } else {
        console.error('Expected an overlay element with class "' + overlaySelector + '"')
    }
}

init();

